<template>
  <div class="campaigns-table">
    <v-simple-table>
      <thead>
        <tr class="campaigns-table__header">
          <th />
          <th>Name</th>
          <th>Created</th>
          <th>Updated</th>
          <th>Data Expiration Date</th>
          <th>Days Remaining</th>
          <th v-if="isPulseAdmin">
            Note
          </th>
          <th v-if="isPulseAdmin || isPulseUser" />
        </tr>
      </thead>
      <tbody class="search-table__content">
        <tr v-for="(item, i) in sources" :key="i">
          <td>
            <img
              :src="sourceImage(item)"
              alt="source image" style="max-width: 50px; max-height: 50px;padding-top: 6px"
            >
          </td>
          <td class="py-1">
            <div class="campaigns-table__campaign-header mb-1">
              {{ item.name }}
              <div class="text--disabled" style="font-size: 11px;">
                ID: {{ item.id }}
              </div>
            </div>
          </td>
          <td class="py-1">
            <div class="campaigns-table__campaign-header mb-1">
              <div :title="getDateWithDayNameAndTime(item.createdAt)">
                {{ getDate(item.createdAt) }}
              </div>
            </div>
          </td>
          <td class="py-1">
            <div class="campaigns-table__campaign-header mb-1">
              <div :title="getDateWithDayNameAndTime(item.updatedAt)">
                {{ getDate(item.updatedAt) }}
              </div>
            </div>
          </td>
          <td class="py-1">
            <div class="campaigns-table__campaign-header mb-1">
              {{ item && item.dataExpirationDate ? getDateAsUTC(item.dataExpirationDate) : '-' }}
            </div>
          </td>
          <td class="py-1">
            <div class="campaigns-table__campaign-header mb-1">
              <div v-if="item && item.dataExpirationDate">
                {{ daysRemaining(item.dataExpirationDate) }}
                <span v-if="daysRemaining(item.dataExpirationDate) > 90">
                  <v-icon size="18" color="#2ecc71">
                    mdi-check
                  </v-icon>
                </span>
                <span v-else-if="daysRemaining(item.dataExpirationDate) > 30">
                  <v-icon size="18" color="#f1c40f">
                    mdi-information-outline
                  </v-icon>
                </span>
                <span v-else>
                  <v-icon size="18" color="#e74c3c">
                    mdi-close-circle
                  </v-icon>
                </span>
              </div>
              <div v-else>
                -
              </div>
            </div>
          </td>
          <td
            v-if="isPulseAdmin"
            class="py-1"
          >
            <div
              class="campaigns-table__campaign-header mb-1"
              v-html="item.notes ? item.notes.replaceAll('\n', '<br>') : '-'"
            />
          </td>
          <td
            v-if="isPulseAdmin || isPulseUser"
            class="v-menu-attach-parent"
          >
            <v-menu v-if="!item.isSystemImage" nudge-top="-5px" nudge-left="15px" :offset-y="true">
              <template #activator="{ on, attrs }">
                <v-btn
                  class="elevation-0 outlined-btn px-12"
                  height="34px"
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('click')"
                >
                  Actions
                </v-btn>
              </template>
              <OuterList
                title="Actions"
                :items="outerListItems"
                @click="$emit('action', { event: $event, item })"
              />
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";
import datesMixin from "@/utils/datesMixin.js";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "SourceTable",
  components: { OuterList },
  mixins: [datesMixin],
  props: {
    sources: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      menuOpened: false,
      outerListItems: [
        {
          label: "Edit Source",
          icon: "mdi-pencil",
          value: "edit"
        },
        {
          label: "View Contacts",
          icon: "mdi-account",
          value: "viewContact",
        },
        {
          label: "Delete Source",
          icon: "mdi-delete-outline",
          value: "delete",
        },

      ],
    };
  },
  computed: {
    ...mapGetters('user', ['isPulseAdmin', 'isPulseUser']),
  },
  methods: {
    sourceImage(item){
      if(item.image && item.isSystemImage){
        return require("@/assets/sources/" + item.image);
      }else if(item.image && !item.isSystemImage){
        return item.imageUrl;
      }else if(!item.image || typeof item.image === 'undefined'){
        return require("@/assets/sources/default_source.jpg");
      }
      return null;
    },
    daysRemaining(expiryDate) {
      const today = dayjs();
      // expiryDate comes in as UTC which causes problems with local times
      // so if we add the hours from UTC to handle different timezones and also any hours based on current time
      // this will allow the days remaining to show correctly
      const hoursFromUTC = (new Date()).getTimezoneOffset() / 60;
      const expiry = dayjs(expiryDate).add(hoursFromUTC + today.get('hour'), 'hour');
      const timeDifference = expiry.valueOf() - today.valueOf();
      const dayDifference = timeDifference / (1000 * 3600 * 24);

      return dayDifference.toFixed(0);
    }
  }
};
</script>

<style scoped>
.panel-button {
  font-family: "Open Sans", sans-serif;
  font-size: 13px !important;
  color: #66788e;
  text-transform: none;
  background: #ecf1f5 !important;
}
.panel-button[aria-expanded=true] {
  color: white;
  background: #2b84eb !important;
  opacity: 1 !important;
}
</style>
