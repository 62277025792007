<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      {{ source.name ? 'Edit Source' : 'New Source' }}
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <CustomTextInput
          v-model="temp_source.name"
          class="flex-grow-1 mt-3"
          placeholder="Source name"
          header="Name"
          :maxlength="150"
          :required="true"
        />
      </validation-observer>
      <v-row>
        <v-col cols="12">
          <CustomFileUpload
            v-model="temp_source.imageFile"
            placeholder="Select Preview Image"
            header="Preview Image"
            accept="image/*"
          />
        </v-col>
      </v-row>

      <div class="input-label mb-0 mt-6">
        Expiration Date
      </div>
      <CustomDatePicker
        :date.sync="temp_source.dataExpirationDate"
        placeholder="Set an Expiration Date"
        class="mt--2"
      />

      <div class="input-label mb-0">
        Note
      </div>
      <CustomTextarea
        v-model="temp_source.note"
        placeholder="Note"
        style="height: 150px"
      />

      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="on_save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDatePicker from "@/sharedComponents/CustomDatePicker";
import CustomTextarea from "@/sharedComponents/CustomTextareaV2";
import datesMixin from "@/utils/datesMixin.js";
import CustomFileUpload from "@/sharedComponents/CustomFileUpload";

export default {
  name: "SourceEdit",
  components: {
    CustomTextarea,
    CustomTextInput,
    CustomDatePicker,
    CustomFileUpload,
  },
  mixins: [datesMixin],
  props:{
    source:{
      default: () => ({})
    },
  },
  data(){
    return {
      temp_source: {
        name: '',
        dataExpirationDate: '',
        note: '',
        image: null,
        imageFile: null,
      },
    }
  },
  mounted(){
    this.temp_source = { ...this.source }
    if (this.source.notes) {
      this.temp_source.note = this.source.notes.replaceAll("<br>", "\n");
    }
  },
  methods: {
    async on_save() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      this.$emit('save', {
        name: this.temp_source.name,
        dataExpirationDate: this.getSystemDateFormatForHour(this.temp_source.dataExpirationDate, 12),
        notes: this.temp_source.note,
        imageFile: this.temp_source.imageFile,
      })
    }
  },
}
</script>
