<template>
  <div data-app class="custom-date-picker">
    <div class="field__subheader">
      <span
        v-if="required"
        class="field__subheader--required"
      >
        *
      </span>
      {{ header }}
    </div>
    <validation-observer ref="textInput">
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        :close-on-click="true"
        transition="scale-transition"
        class="date-picker"
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{}">
          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required
            }"
            mode="eager"
          >
            <v-text-field
              :value="dateAndTime"
              class="mt-4"
              :class="{'text-field-diabled': disabled}"
              dense
              readonly
              outlined
              clearable
              :error-messages="errors"
              height="30px"
              :placeholder="placeholder"
              @click:clear="clear_date"
              @click-on-append-icon="showDatePicker = true"
              @click="showDatePicker = true"
            >
              <template #append>
                <v-btn
                  class="date-picker__button"
                  small
                  icon
                  @click="showDatePicker = true"
                >
                  <v-icon size="18" color="#66788e">
                    mdi-calendar-range
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </validation-provider>
        </template>

        <v-date-picker
          v-if="showDatePicker"
          :value="date"
          :first-day-of-week="7"
          locale="en"
          no-title
          @input="
            $emit('input', $event);
            showDatePicker = false;
            $emit('update:date', $event);
          "
        />
      </v-menu>
    </validation-observer>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "CustomDatePicker",
  mixins: [datesMixin],
  props: {
    header: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => "Choose A Date",
      type: String,
    },
    date: {
      default: () => {},
    },
    outer_date: {
      default: () => null,
    },
    disabled: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      showDatePicker: false,
    };
  },
  computed: {
    dateAndTime() {
      if (!this.date) {
        return '';
      }

      if (this.outer_date) {
        return this.outer_date;
      }

      return this.getDateAsUTC(this.date);
    },
  },

  methods: {
    onInputMenu(value) {
      if (value === false || value === undefined) {
        this.showDatePicker = false;
      }
    },
    clear_date() {
      this.$emit('input', null);
      this.$emit('update:date', null);
    }
  },
};
</script>

<style lang="scss">
.date-picker {
  &__button {
    margin-top: -2px;
  }
}

.date-picker-text-field {
  .v-input__slot {
    width: 150px;
  }
}
.custom-date-picker {
  .text-field-diabled {
    .v-input__control {
      .v-input__slot {
        fieldset {
          background-color: #e5eaed !important;
        }
      }
    }
  }
  .v-text-field {
    fieldset {
      border-radius: 4px !important;
      border: solid 1px #dee7ee !important;
      background-color: #ffffff !important;
    }
    input {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .v-input__slot {
      max-height: 44px !important;
      min-height: 44px !important;
      height: 44px !important;
    }
    .v-input__append-inner {
      margin-top: 11px !important;
      margin-right: -4px;
    }
    .v-icon.mdi-close {
      font-size: 20px;
      margin-top: -1px;
    }
  }
}

.field__subheader--required {
  color: #e74c3c;
}
</style>
