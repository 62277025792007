<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Sources List"
      regular-button-text="New Source"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    />

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for sources"
      :current-page-number="sources_page_number"
      :current-page-size="sources_page_size"
      :count-total="sources_total"
      :page-sizes="[25, 50, 100]"
      @next-page="get_sources_next_page"
      @prev-page="get_sources_prev_page"
      @change-page-size="sources_change_page_size"
      @search="(search_string) => on_search(search_string)"
    />

    <SourceTable
      :sources="sources"
      @action="handleAction($event)"
    />

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="560px"
      @click:outside="edited_source = {}"
    >
      <SourceEdit
        v-if="dialog"
        :source="edited_source"
        @save="save_source"
        @dismiss="dialog = false; edited_source = {}"
      />
    </v-dialog>
    <v-dialog v-model="delete_dialog" scrollable max-width="360px" @click:outside="edited_source = {}">
      <DeleteConfirmationDialog
        v-if="delete_dialog"
        header="Delete source"
        subheader="Do you really want to delete this source?"
        @delete="delete_source(delete_item_id)"
        @dismiss="delete_dialog = false; delete_item_id = false;"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import SourceTable from "@/views/Contacts/components/SourceTable";
import SourceEdit from "@/views/Contacts/components/SourceEdit";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";


export default {
  name: "SourcesView",
  metaInfo: {
    title: 'Sources'
  },
  components: {
    SourceEdit,
    SourceTable,
    SearchFieldRowWithPagination,
    PageHeader,
    DeleteConfirmationDialog
  },
  data() {
    return {
      edited_source: {},
      dialog: false,
      sources: [],
      sources_total: 0,
      sources_page_number: 1,
      sources_page_size: 25,
      search_string: null,
      delete_dialog: false,
    };
  },
  created() {
    this.get_available_sources();
  },
  methods: {
    async delete_source(id) {
      await this.$rest.source.delete_resource(id);
      await this.get_available_sources();
      this.delete_dialog = false;
      this.delete_item_id = null;
      this.$notifier.success("Successfully deleted the source.");
    },
    async handleAction(action) {
      if (action.event.value === "delete") {
        this.delete_item_id = action.item.id;
        this.delete_dialog = true;
      } else if (action.event.value === "viewContact") {

        await this.$router.push({
          name: "ContactsSearch",
          query: {
            outer_criteria: JSON.stringify({
              "operator": "AND",
              "groups":[
                {
                  "operator": "AND",
                  "conditions":[
                    {
                      "field" :"contact.source",
                      "operator":"is",
                      "value": action.item.id
                    }
                  ]
                }
              ]
            })
          }
        });
      } else if(action.event.value === "edit") {
        this.edited_source = {...action.item}
        this.dialog = true;
      }
    },
    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_sources();
    },
    async save_source(source) {
      if (this.edited_source.id) {
        await this.$rest.source.put_resource(this.edited_source.id, source)
      } else {
        await this.$rest.source.post_resource(source)
      }
      this.edited_source = {};
      this.dialog = false;
      this.search_string = null
      await this.get_available_sources()
    },
    async sources_change_page_size(size) {
      this.sources_page_size = size;
      this.sources_page_number = 1;
      await this.get_available_sources();
    },
    async get_sources_next_page() {
      this.sources_page_number += 1;
      await this.get_available_sources();
    },
    async get_sources_prev_page() {
      this.sources_page_number -= 1;
      await this.get_available_sources();
    },
    async get_available_sources() {
      const resp = await this.$rest.source.get_collection({
        limit: this.sources_page_size,
        page: this.sources_page_number,
        name: this.search_string || null,
        sort: ['createdAt:desc']
      });
      this.sources = resp.data.items;
      this.sources_total = resp.data.totalCount;
    },
  },
};
</script>
